import React, { useState } from "react";
import NavBar from '../components/NavBar';
import Contact_pri from '../components/Contact_pri';
import Footer from '../components/footer';


const Contact = () => {

    
  return (
      <>
      <NavBar />
      <Contact_pri />
      <Footer />
      </>
  );
}

export default Contact;